import { apiGet, apiPost, apiDelete } from './api';

export const apiGetQueue = async () => {
	return await apiGet(`/admin/queue`);
};

export const apiGetTranslationServiceHealth = async () => {
	return await apiGet(`/admin/translation_service_health`);
};

export const apiPostResendTask = async (id) => {
	return await apiPost(`/admin/dublicate_queue/${id}`);
};
