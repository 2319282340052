/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-06-10
 *
 * ScrollToTop
 * Scroll page to top when page content changes.
 * Alternatively it's also possible to scroll to the anchor within a page e.g /#features
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, hash, state } = useLocation();

  useEffect(() => {
    document.getElementById("hamburger").checked = false;
    if (state && state.scrollToTop === false) {
      return;
    }
    if (!hash) {
      window.scrollTo(0, 0);
      return;
    } 
    setTimeout(() => {
      const el = document.querySelector(hash);
      if (!el) {
        window.scrollTo(0, 0);
        return;
      }
      el.scrollIntoView({ behavior: 'smooth' });
    }, 20);
  }, [ pathname, hash, state ]);
  return null;
};

export default ScrollToTop;
