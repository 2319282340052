/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserByAuthToken } from '../../store/actions';
import Header from './components/Header';
import Main from './components/Main';
import Messages from './components/Messages';
import Footer from './components/Footer';
import Svg from './components/Svg';
import {initGA, GApageView} from '../../helpers/google-analytics';

import './Frontend.css';

import '../Form/Form.css';
import '../Table/Table.css';

const App = ({ user_id = 0, user_token = '', getUserByAuthToken = () => {} }) => {

  const { pathname } = useLocation();
  const dirtyPath = [
    '/admin',
    //'/workshop',
    '/appx',
    '/sign_in',
    '/sign_up',
    '/password_reset',
    '/email_verification'
  ];

  const dirtyView = dirtyPath.every((item) => {
      // console.log("Searching url '"+ item + "' in: '" + pathname + "' and found at pos: " + pathname.indexOf(item));
      return pathname.indexOf(item) === -1;
  });

  useEffect(() => {
    /**
     * if there's a token but no user info,
     * trigger token validation and user data fetch
     */
    if (user_token && !user_id) {
      getUserByAuthToken();
    }
  }, [ user_id, user_token, getUserByAuthToken ]);

  useEffect(() => {
    initGA();
    GApageView("main");
  }, []);

  /**
   * Wait for the token to be resolved before rendering
   */
  if (user_token && !user_id && pathname !== '/') {
    return null;
  }

  return (
    <div id="tl-app" className={'tl-app' + (dirtyView ? '' : ' appx') }>
      <Header />
      <Main />
      <Footer />
      <Messages />
      <Svg />
    </div>
  );
};


App.propTypes = {
  user_id: PropTypes.number,
  user_token: PropTypes.string,
  getUserByAuthToken: PropTypes.func.isRequired
};


export default connect(
  state => ({
    user_id: state.appUser.user_id,
    user_token: state.appUser.token
  }),
  dispatch => ({
    getUserByAuthToken: () => dispatch(getUserByAuthToken())
  })
)(App);
