/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */


const usersListState = {
  usersList: [],
  usersListIsLoading: false
};


export default ( state = usersListState, action = {} ) => {
  const { type } = action;
  switch (type) {
    case 'setUsersList': {
      const { usersList } = action;
      return { ...state, usersList };
    }
    case 'setUsersListIsLoading': {
      const { usersListIsLoading } = action;
      return { ...state, usersListIsLoading };
    }
    default:
      return state;
  }
};
