import ReactGA from 'react-ga';

const env = process.env.NODE_ENV;

export const initGA = () => {
	if (env === 'production') {
		ReactGA.initialize('UA-180497226-1');
	}
};

export const GApageView = (page) => {
	if (env === 'production') {
		ReactGA.pageview(page);
	}
};
