/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { apiGet, apiPost } from './api';
import { getAppUserStoredToken, setAppUserStoredToken } from '../helpers';


/**
 * apiGetUserByAuthToken
 * @returns onSuccess {
 *    user_id: 1,
 *    name: "Jevgeni Virves",
 *    email: "jevgeni.virves@regio.ee",
 *    tos_accepted: true,
 *    status: "verified",
 *    user_role: "admin",
 *    created_at: 1591782359469
 * }
 * @returns onError null
 */
export const apiGetUserByAuthToken = async () => {
  const response = await apiGet('/user');
  if (response && response.id) {
    const { id: user_id, name, email, tos_accepted, status, user_role, created_at, company_name, billing_address, billing_email, registration_number, vat_number } = response;
    return {
      user_id,
      name,
      email,
      tos_accepted: tos_accepted ? true : false,
      status,
      user_role: user_role === 'admin' ? 'admin' : 'user',
      created_at, company_name, billing_address, billing_email, registration_number, vat_number,
      token: getAppUserStoredToken()
    };
  }
  return null;
};


/**
 * apiPostUserSignUp
 * @returns onSuccess {
 *    user_id: 1,
 *    name: "Jevgeni Virves",
 *    email: "jevgeni.virves@regio.ee",
 *    tos_accepted: true,
 *    status: "verified",
 *    user_role: "admin",
 *    created_at: 1591782359469,
 *    token: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
 * }
 * @returns onError {
 *    errors: {
 *      name: [ "This field is required." ],
 *      email: [ "This field is required." ]
 *    }
 * }
 */
export const apiPostUserSignUp = async ({ name, email, password, password_repeat, tos_accepted }) => {
  let response = await apiPost('/user/sign_up', { name, email, password, password_repeat, tos_accepted });
  response = response || {};
  if (response.token) {
    const { token } = response;
    setAppUserStoredToken({ token });
    return await apiGetUserByAuthToken();
  }
  return response;
};


/**
 * apiPostUserEmailVerificationToken
 * @returns onSuccess {
 *    status: "ok"
 * }
 * @returns onError { errors: "email": [ "Email verification failed" ] }
 */
export const apiPostUserEmailVerificationToken = async ({ verification_token }) => {
  return await apiPost('/user/email_verification', { verification_token });
};


/**
 * apiPostUserSignIn
 * @returns onSuccess {
 *    user_id: 1,
 *    name: "Jevgeni Virves",
 *    email: "jevgeni.virves@regio.ee",
 *    tos_accepted: true,
 *    status: "verified",
 *    user_role: "admin",
 *    created_at: 1591782359469,
 *    token: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
 * }
 * @returns onError { errors: 'Sign in failed' }
 */
export const apiPostUserSignIn = async ({ email, password }) => {
  const response = await apiPost('/user/sign_in', { email, password });
  if (response && response.token) {
    const { token } = response;
    setAppUserStoredToken({ token });
    return await apiGetUserByAuthToken();
  }
  return { errors: 'Sign in failed' };
};


/**
 * apiPostUserSignOut
 * @returns onSuccess {
 *    status: "ok"
 * }
 * @returns onError {
 *    errors: { "Sign out failed" }
 * }
 */
export const apiPostUserSignOut = async () => {
  const response = await apiPost('/user/sign_out');
  if (response && response.status === 'ok') {
    return response;
  }
  return { errors: 'Sign out failed' };
};











export const apiGetUser = async ({ user_id }) => {
  const response = await apiGet(`/user/${user_id}`);
  if (response && response.id) {
    const { id: user_id, name, email, tos_accepted, status, user_role, created_at, company_name, billing_address, billing_email, registration_number, vat_number } = response;
    return { user_id, name, email, tos_accepted, status, user_role, created_at, company_name, billing_address, billing_email, registration_number, vat_number };
  }
  return response;
};



export const apiPostUserCreate = async ({ name, email, tos_accepted, status, user_role, password }) => {
  const response = await apiPost('/user/create', { name, email, tos_accepted, status, user_role, password });
  if (response.status === 'ok' && response.id) {
    return await apiGetUser({ user_id: response.id });
  }
  return response;
};



export const apiPostUserUpdate = async ({ user_id, name, email, tos_accepted, status, user_role, company_name, billing_address, billing_email, registration_number, vat_number }) => {
  if (user_id) {
    const response = await apiPost(`/user/${user_id}/update`, { user_id, name, email, tos_accepted, status, user_role, company_name, billing_address, billing_email, registration_number, vat_number });
    if (response && response.status === 'ok') {
      return await apiGetUser({ user_id });
    }
  }
  const response = await apiPost('/user/update', { name, company_name, billing_address, billing_email, registration_number, vat_number });
  if (response && response.status === 'ok') {
    return await apiGetUserByAuthToken();
  }
  return response;
};



export const apiPostUserChangeUserPassword = async ({ user_id, password, password_repeat }) => {
  return await apiPost(`/user/${user_id}/change_user_password`, { password, password_repeat });
};


export const apiPostSendVerificationEmail = async () => {
  return await apiPost(`/user/send_verification_email`);
};
