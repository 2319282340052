/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { apiGetUsers } from '../../api';



export const setUsersList = usersList => {
  return { type: 'setUsersList', usersList };
};



export const setUsersListIsLoading = usersListIsLoading => {
  return { type: 'setUsersListIsLoading', usersListIsLoading: usersListIsLoading ? true : false };
};



export const getUsers = () => {
  return async dispatch => {
    dispatch(setUsersListIsLoading(true));
    const usersList = await apiGetUsers();
    dispatch(setUsersList(usersList))
    dispatch(setUsersListIsLoading(false));
    return usersList;
  };
};
