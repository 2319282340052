/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dismissMessage } from '../../../../store/actions';

import './Messages.css';



const Messages = ({ messages = [], dismissMessage = () => {} }) => {
  return messages.length ? (
    <div className='tl-messages'>
      {
        messages.map(message => (
          <div key={message.id} className={ 'tl-message tl-message-' + message.type + (message.dismissed ? ' tl-message-dismissed' : '') } style={{ animationDuration: message.duration + 'ms' }}>
            <p>{ message.text }</p>
            <button type="button" className="tl-message-dismiss-button" onClick={ () => dismissMessage(message) }>Close</button>
          </div>
        ))
      }
    </div>
  ) : null;
};


Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    dismissed: PropTypes.bool.isRequired
  })).isRequired,
  dismissMessage: PropTypes.func.isRequired
};


export default connect(
  state => ({
    messages: state.messages
  }),
  dispatch => ({
    dismissMessage: message => dispatch(dismissMessage(message))
  })
)(Messages);
