/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { appUserAuthTokenName } from '../config';

import { getCookie, setCookie } from './cookie';



export const getAppUserStoredToken = () => {
  return getCookie(appUserAuthTokenName) || localStorage.getItem(appUserAuthTokenName) || null;
};


export const setAppUserStoredToken = ({ token, remember_me }) => {
  if (token) {
    setCookie(appUserAuthTokenName, token);
    if (remember_me) {
      localStorage.setItem(appUserAuthTokenName, token);
    }
  } else {
    removeAppUserStoredToken();
  }
};


export const removeAppUserStoredToken = () => {
  setCookie(appUserAuthTokenName, '', -100);
  localStorage.removeItem(appUserAuthTokenName);
};
