/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-23
 */

import { apiGet } from './api';



export const apiGetUsers = async () => {
  const response = await apiGet('/users');
  if (!Array.isArray(response)) {
    return [];
  }
  const usersList = response.map(user => {
    const { id: user_id, username: name, email, tos_accepted, user_status: status, user_role, created_at } = user;
    return {
      user_id,
      name,
      email,
      tos_accepted,
      status,
      user_role,
      created_at
    };
  });
  return usersList;
};
