/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */



export const getCookie = name => {
  name = name + '=';
  const cookieArr = decodeURIComponent(document.cookie).split(';');
  for (let i = 0, len = cookieArr.length; i < len; i++) {
    let c = cookieArr[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};


export const setCookie = (name, value, expires) => {
  if (expires) {
    var date = new Date();
    date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
    expires = 'expires='+ date.toUTCString() + ';';
  } else {
    expires = '';
  }
  document.cookie = name + '=' + value + ';' + expires + 'path=/';
};
