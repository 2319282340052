/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-15
 */

import { apiPost } from './api';



export const apiPostPasswordInitiatePasswordReset = async ({ email }) => {
  return await apiPost('/password/initiate_password_reset', { email });
};


export const apiPostPasswordResetPassword = async ({ email, password, password_repeat, password_reset_token }) => {
  return await apiPost('/password/reset_password', { email, password, password_repeat, password_reset_token });
};


export const apiPostPasswordChangePassword = async ({ current_password, password, password_repeat }) => {
  return await apiPost('/user/change_password', { current_password, password, password_repeat });
};
