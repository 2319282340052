/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import React from 'react';
import { Link } from 'react-router-dom';
import HeaderNavi from './HeaderNavi';
import HeaderLanguages from './HeaderLanguages';

const Header = () => (
  <header className="header">
    <div className="header__inner">
        <Link to="/" title="TerraLexicon" className="header__logo tl-app-header-logo">
          <svg>
            <use href="#header-logo"></use>
          </svg>
        </Link>

        <input type="checkbox" id="hamburger" title="Open Menu" className="nav__hinput js-widget-opener button-input" />
        <label htmlFor="hamburger" className="nav__hamburger">
          <span className="nav__label">Open menu</span>
          <span className="nav__hline nav__hline--top"></span>
          <span className="nav__hline nav__hline--middle"></span>
          <span className="nav__hline nav__hline--bottom"></span>
        </label>
        <h1 className="header__slogan">Minimize your time <br></br>and labour costs</h1>
        <HeaderNavi />
    </div>
  </header>
);

export default Header;
