/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */


const orderState = {
  order_id: 0,            // number   1
  object_type: '',        // string   geonames
  order_token: '',        // string   6ce0ac7e-68bd-4679-88e4-9ea9e891148e
  order_name: '',         // string   test321
  from_lang: '',          // string   lv
  to_lang: '',            // string   de
  order_added: '',        // string   2020-05-19T08:42:13Z
  order_status: '',       // string   service_received_request
  has_error: null,
  extractedData: [
    /*
    {
      uuid: '69537291-8715-43ef-a7ba-282f51be9409',
      label: 'Ķekava'
    },
    {
      uuid: '4b4dcb6f-1d95-45bc-ad56-03dcf52dcf61',
      label: 'Krāslava'
    }
    */
  ],
  files: [
    /*
    'KK900_Latv_tituls_27km2',
    'KK911_Latv_tituls_27km2'
    */
  ],
  canBeTranslated: false,
  canUserApproveTranslations: false,
  showTranslations: false,
  orderIsLoading: false,
  translationPreview: {}
};


export default (state = orderState, { type, payload }) => {
  switch (type) {
    case 'setOrder':
      {
        const { order_id, order_token, order_name, order_status, order_added, object_type, from_lang, to_lang, files, extractedData, showTranslations, canUserApproveTranslations, canBeTranslated, has_error, too_old_tool, translationPreview } = payload;
        return { ...state, order_id, order_token, order_name, order_status, order_added, object_type, from_lang, to_lang, files, extractedData, showTranslations, canBeTranslated, canUserApproveTranslations, has_error, too_old_tool, translationPreview };
      }
    case 'clearOrder':
      return orderState;
    case 'setOrderIsLoading':
      return { ...state, orderIsLoading: payload ? true : false };
    case 'setOrderStatus':
      {
        const { order_id, order_status, has_error, too_old_tool } = payload;
        if (order_id === state.order_id && order_status !== state.order_status) {
          return { ...state, order_status, has_error, too_old_tool };
        }
        if (order_id === state.order_id && has_error !== state.has_error) {
          return { ...state, order_status, has_error, too_old_tool };
        }
        return state;
      }
    default:
      return state;
  }
};
