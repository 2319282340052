/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { getAppUserStoredToken } from '../../helpers';

const appUserState = {
	user_id: 0,
	name: '',
	email: '',
	tos_accepted: false,
	status: '',
	user_role: '',
	created_at: '',
	token: getAppUserStoredToken(),
	company_name: '', billing_address: '', billing_email: '', registration_number: '', vat_number: ''
};

export default (state = appUserState, { type, payload }) => {
	switch (type) {
		case 'setAppUser': {
			if (!payload) {
				return appUserState;
			}
			const { user_id, name, email, tos_accepted, status, user_role, created_at, token, company_name, billing_address, billing_email, registration_number, vat_number } = payload;
			return { user_id, name, email, tos_accepted, status, user_role, created_at, token, company_name, billing_address, billing_email, registration_number, vat_number };
		}
		case 'unsetAppUser':
			return { ...appUserState, token: '' };
		case 'setAppUserToken':
			return { ...state, token: payload || '' };
		default:
			return state;
	}
};
