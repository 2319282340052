/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { showSuccessMessage, showErrorMessage } from '../../store/actions';
import {
  apiGetOrder,
  apiGetOrderDetailed,
  apiGetOrderStatus,
  apiPostOrderUpdate,
  apiPostOrderNew,
  apiPostOrderInitializeTranslation,
  apiPostOrderApproveTranslations,
  apiPostOrderPaymentWaiting,
  apiPostOrderPaymentDone,
  apiPostOrderFinished,
  apiPostOrderUpdateLabel,
  apiDeleteOrder,
  apiDeleteLabel
} from '../../api';



export const setOrder = orderDetails => {
  return { type: 'setOrder', payload: orderDetails };
};


export const clearOrder = () => {
  return { type: 'clearOrder' };
};


export const setOrderStatus = ({ order_id, order_status, has_error = null }) => {
  return { type: 'setOrderStatus', payload: { order_id, order_status, has_error } };
};


export const setOrderIsLoading = orderIsLoading => {
  return { type: 'setOrderIsLoading', payload: orderIsLoading ? true : false };
};





export const getOrder = ({ order_id }) => {
  return async dispatch => {
    dispatch(setOrderIsLoading(true));
    const response = await apiGetOrder({ order_id });
    if (response.order_id) {
      dispatch(setOrder(response));
    }
    dispatch(setOrderIsLoading(false));
    return response;
  };
};


export const getOrderDetailed = ({ order_id }) => {
  return async dispatch => {
    dispatch(setOrderIsLoading(true));
    const response = await apiGetOrderDetailed({ order_id });
    if (response.order_id) {
      dispatch(setOrder(response));
    }
    dispatch(setOrderIsLoading(false));
  };
};


export const getOrderStatus = ({ order_id }) => {
  return async dispatch => {
    const response = await apiGetOrderStatus({ order_id });
    {
      const { order_id, order_status } = response;
      if (order_id && order_status) {
        dispatch(setOrderStatus({ order_id, order_status }));
      }
    }
  };
};


export const postOrderUpdate = ({ order_id, order_name, object_type, from_lang, to_lang }) => {
  return async dispatch => {
    dispatch(setOrderIsLoading(true));
    const response = await apiPostOrderUpdate({ order_id, order_name, object_type, from_lang, to_lang });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(setOrder(response));
      dispatch(showSuccessMessage('Saved'));
    }
    dispatch(setOrderIsLoading(false));
    return response;
  };
};


export const postOrderNew = ({ order_name, object_type, from_lang, to_lang }) => {
  return async dispatch => {
    dispatch(setOrderIsLoading(true));
    const response = await apiPostOrderNew({ order_name, object_type, from_lang, to_lang });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(setOrder(response));
      dispatch(showSuccessMessage('Saved'));
    }
    dispatch(setOrderIsLoading(false));
    return response;
  };
};


export const postOrderInitializeTranslation = ({ order_id }) => {
  return async dispatch => {
    const response = await apiPostOrderInitializeTranslation({ order_id });
    if (response.status === 'ok') {
      const response = await apiGetOrder({ order_id });
      const { order_status } = response;
      if (order_status) {
        dispatch(setOrderStatus({ order_id, order_status }));
        return { order_status };
      }
    }
    return {};
  };
};


export const postOrderApproveTranslations = ({ order_id }) => {
  return async dispatch => {
    const response = await apiPostOrderApproveTranslations ({ order_id });
    if (response.status === 'ok') {
      const response = await apiGetOrder({ order_id });
      const { order_status } = response;
      if (order_status) {
        dispatch(setOrderStatus({ order_id, order_status }));
        return { order_status };
      }
    }
    return {};
  };
};


export const postOrderPaymentWaiting = ({ order_id }) => {
  return async dispatch => {
    const response = await apiPostOrderPaymentWaiting ({ order_id });
    if (response.status === 'ok') {
      const response = await apiGetOrder({ order_id });
      const { order_status } = response;
      if (order_status) {
        dispatch(setOrderStatus({ order_id, order_status }));
        return { order_status };
      }
    }
    return {};
  };
};


export const postOrderPaymentDone = ({ order_id }) => {
  return async dispatch => {
    const response = await apiPostOrderPaymentDone ({ order_id });
    if (response.status === 'ok') {
      const response = await apiGetOrder({ order_id });
      const { order_status } = response;
      if (order_status) {
        dispatch(setOrderStatus({ order_id, order_status }));
      }
    }
  };
};


export const postOrderFinished = ({ order_id }) => {
  return async dispatch => {
    const response = await apiPostOrderFinished({ order_id });
    if (response.status === 'ok') {
      const response = await apiGetOrder({ order_id });
      const { order_status } = response;
      if (order_status) {
        dispatch(setOrderStatus({ order_id, order_status }));
        return { order_status };
      }
    }
    return {};
  };
};


export const postOrderUpdateLabel = ({ order_id, uuid, label, translation }) => {
	return async dispatch => {
	  const response = await apiPostOrderUpdateLabel({ uuid, label, translation });
	  if (response.status === 'ok') {
		const response = await apiGetOrderDetailed({ order_id });
		if (response.order_id) {
		  dispatch(setOrder(response));
		  dispatch(showSuccessMessage('Saved'));
		  return response;
		}
	  }
	  return response;
	};
  };



  export const deleteOrder = ({ order_id }) => {
	return async dispatch => {
	  const response = await apiDeleteOrder({ order_id });

	  return response;
	};
  };

  export const deleteLabel = ({ order_id, uuid }) => {
	return async dispatch => {
	  const response = await apiDeleteLabel({ order_id, uuid });

	  return response;
	};
  };

export const startGetOrderStatusInterval = () => {
  return (dispatch, getState) => {
    let timeoutTid;
    const getOrderStatus = async () => {
      clearTimeout(timeoutTid);
	  const { order_id, order_status } = getState().order;
      if (!order_id || !order_status) {
        return;
      }
	  const oldStatus = order_status;
      // statuses that need user action
      if (['translation_finished', 'service_extract_finished', 'service_finished_work', 'user_approved_translations', 'payment_done', 'finished'].indexOf(order_status) !== -1) {
        return;
      }
      // for other statuses check status change periodically
      const response = await apiGetOrderStatus({ order_id });
      {
		const { order_id, order_status, has_error } = getState().order;
        if (order_id && ((response.order_status && order_status !== response.order_status) || (has_error !== response.has_error))) {
          const { order_status, has_error } = response;
          if (
			  order_status === 'service_extract_finished' ||
			  order_status === 'service_finished_work' ||
			  (order_status === 'payment_done' && oldStatus === 'translation_started') ||
			  (order_status === 'translation_finished' && oldStatus === 'translation_started')
			) {
            dispatch(getOrderDetailed({ order_id }));
          } else {
            dispatch(setOrderStatus({ order_id, order_status, has_error }));
          }
        }
      }
      timeoutTid = setTimeout(getOrderStatus, 3000);
    };
    clearTimeout(timeoutTid);
    timeoutTid = setTimeout(getOrderStatus, 3000);
    return function clearGetOrderStatusInterval () {
      clearTimeout(timeoutTid);
    }
  };
};
