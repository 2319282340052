/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { showSuccessMessage, showErrorMessage } from '../../store/actions';
import {
  apiGetUser,
  apiPostUserCreate,
  apiPostUserUpdate,
  apiPostUserChangeUserPassword
} from '../../api';




export const setUser = user => {
  return { type: 'setUser', user };
};


export const clearUser = () => {
  return { type: 'clearUser' };
};


export const setUserIsLoading = userIsLoading => {
  return { type: 'setUserIsLoading', userIsLoading: userIsLoading ? true : false };
};




export const getUser = ({ user_id }) => {
  return async dispatch => {
    dispatch(setUserIsLoading(true));
    const response = await apiGetUser({ user_id });
    if (response.user_id) {
      dispatch(setUser(response));
    }
    dispatch(setUserIsLoading(false));
    return response;
  };
};


export const postUserCreate = ({ name, email, tos_accepted, status, user_role, password }) => {
  return async dispatch => {
    dispatch(setUserIsLoading(true));
    const response = await apiPostUserCreate({ name, email, tos_accepted, status, user_role, password });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(showSuccessMessage('Saved'));
    }
    dispatch(setUserIsLoading(false));
    return response;
  };
};


export const postUserUpdate = ({ user_id, name, email, tos_accepted, status, user_role, company_name, billing_address, billing_email, registration_number, vat_number }) => {
  return async dispatch => {
    dispatch(setUserIsLoading(true));
    const response = await apiPostUserUpdate({ user_id, name, email, tos_accepted, status, user_role, company_name, billing_address, billing_email, registration_number, vat_number });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(setUser(response));
      dispatch(showSuccessMessage('Saved'));
    }
    dispatch(setUserIsLoading(false));
    return response;
  };
};


export const postUserChangeUserPassword = ({ user_id, password, password_repeat }) => {
  return async dispatch => {
    dispatch(setUserIsLoading(true));
    const response = await apiPostUserChangeUserPassword({ user_id, password, password_repeat });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(showSuccessMessage('Password changed'));
    }
    dispatch(setUserIsLoading(false));
    return response;
  };
};
