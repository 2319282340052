/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { apiGetOrders } from '../../api';


export const setOrdersList = ordersList => {
  return { type: 'setOrdersList', payload: ordersList || [] };
};


export const setOrdersListIsLoading = ordersListIsLoading => {
  return { type: 'setOrdersListIsLoading', payload: ordersListIsLoading ? true : false };
};


export const getOrders = () => {
  return async dispatch => {
    dispatch(setOrdersListIsLoading(true));
    const ordersList = await apiGetOrders();
    dispatch(setOrdersList(ordersList))
    dispatch(setOrdersListIsLoading(false));
    return true;
  };
};
