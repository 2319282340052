/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './ScrollToTop';
import VerifyEmailMessage from './Messages/VerifyEmailMessage';

const Main = ({ user_id = 0, user_status = '' }) => (
  <div className="tl-app-main">
    <ScrollToTop />
    { user_id && user_status !== 'confirmed' ? <VerifyEmailMessage /> : null }
    <Routes />
  </div>
);


Main.propTypes = {
  user_id: PropTypes.number,
  user_status: PropTypes.string
};


export default connect(
  state => ({
    user_id: state.appUser.user_id,
    user_status: state.appUser.status
  })
)(Main);
