/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

 import { apiGet, apiPost } from './api';



 export const apiGetOrders = async () => {
	const response = await apiGet('/orders');
	const ordersList = response.map(order => {
	  const { id: order_id, order_token, order_name, order_status, order_added, object_type, from_lang, to_lang, user_id__username: username, user_id__email: email } = order;
	  return {
		order_id,
		order_token,
		order_name,
		order_status,
		order_added,
		object_type,
		from_lang,
		to_lang,
		username,
		email
	  };
	});
	return ordersList;
  };

  export const apiGetCart = async () => {
	const {data} = await apiGet('/cart');

	return data;
  };

  export const apiGetInvoicableOrders = async () => {
	const {data} = await apiGet('/invoices_to_create');

	return data;
  };

  export const apiMakeInvoice = async (user_id) => {
	const {data} = await apiPost('/create_invoice', {user: user_id});

	return data;
  };


