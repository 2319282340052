/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */


const ordersListState = {
  ordersList: [
    /*
    {
      order_id: 1,
      order_token: "6ce0ac7e-68bd-4679-88e4-9ea9e891148e",
      order_name: "tes",
      object_type: "geonames",
      from_lang: "lv",
      to_lang: "de",
      order_status: "service_received_request",
      order_added: "2020-05-19T08:42:13Z"
    }
    */
  ],
  ordersListIsLoading: false
};


export default (state = ordersListState, { type, payload }) => {
  switch (type) {
    case 'setOrdersList':
      return { ...state, ordersList: payload };
    case 'setOrdersListIsLoading':
      return { ...state, ordersListIsLoading: payload ? true : false };
    default:
      return state;
  }
};
