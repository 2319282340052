/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { combineReducers } from 'redux';

import appUser from './appUser';
import messages from './messages';
import order from './order';
import ordersList from './ordersList';
import usersList from './usersList';
import user from './user';


const rootReducer = combineReducers({
  messages,
  appUser,
  order,
  ordersList,
  usersList,
  user
});


export default rootReducer;
