/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Footer = () => (
<footer className="footer">
    <div className="footer__upper">
        <div className="footer__inner-block flex">
            <div className="flex flex__33-33-33">
                <div className="flex__column">
                    <Link to="/" title="TerraLexicon" className="footer__logo">
                        <svg className="ico-svg">
                            <use href="#footer-logo"></use>
                        </svg>
                    </Link>
                    <p className="pr-2">Minimize your time and labour costs</p>
                    {/* <p className="pr-2">After trying out TerraLexicon, please fill out the questionnaire. This will help us to improve the final version of TerraLexicon to serve your needs.</p>
                    <a className="button button--small" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSc2ytVvlCHnWOpbCMqeAj3gwlwGxKPlMZHBXkzpW8JcejimIA/viewform">Feedback</a> */}
                </div>
                <div className="flex__column border-left border-light-gray">
                    <ul className="footer__menu">
                        <li className="footer__menu-item">
                            <NavLink className="footer__menu-link" to="/instructions">Instructions                                                   </NavLink>
                        </li>
                        <li className="footer__menu-item">
                            <NavLink className="footer__menu-link" to="/privacy_policy">Privacy Policy</NavLink>
                        </li>
                        <li className="footer__menu-item">
                            <NavLink className="footer__menu-link" to="/eula">EULA</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="flex__column flex flex-vc flex-hc border-left border-light-gray">
                <div className="footer__co">
                        co-financed by:
                        <img className="footer__co-img" src="/img/eu.png" alt="" />
                        <span className="footer__co-text">"Regio" Ltd (Estonia) and "Jāņa sēta" Ltd (Latvia) project "Geoname translator for maps" (Est-Lat112) has received the support of Interreg Estonia-Latvia programme. The project is being implemented in the Estonian – Latvian (EST-LAT) cross-border cooperation programme in the thematic objective "Increasing the competitiveness of SMEs" priority axis "Active and attractive business environment." The total eligible cost of the project is 356 166.20 EUR, of which 251 043.88 EUR would be provided by the European Regional Development Fund, the Estonian – Latvian cross-border cooperation programme Interreg.<br></br><br></br>
                        This website reflects the views of the author. The managing authority of the programme is not liable for how this information may be used.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="footer__lower">
        <div className="footer__inner-block">
            2021. Jāņa sēta, Regio.
        </div>
    </div>
</footer>
);

export default Footer;
