/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';

import rootReducer from './reducers';


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
