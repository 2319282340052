/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';


const VerifyEmailMessage = () => (
  <Switch>
    <Route path="/appx">
      <p style={{ margin: '-21px 0 0 0', padding: '5px', background: 'rgba(255, 255, 0, 0.4)', textAlign: 'center' }}>
        <Link to="/appx/account_details/verify_email">Please verify your e-mail</Link>
      </p>
    </Route>
  </Switch>
);


export default VerifyEmailMessage;
