/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';


const asyncComponent = importModule => {
  return (props) => {
    const [ module, setModule ] = useState(null);
    useEffect(() => {
      importModule().then(module => setModule(module));
    }, []);
    if (!module) {
      return <p style={{ margin: 0, padding: '20px 0', textAlign: 'center', fontWeight: 600, color: 'rgba(0, 0, 0, 0.5)' }}>Loading content...</p>;
    }
    const Component = module.default;
    return <Component {...props} />;
  }
};


const LandingPage             = asyncComponent(() => import('../../LandingPage'));
const PricingPage             = asyncComponent(() => import('../../PricingPage'));
const FaqPage                 = asyncComponent(() => import('../../FaqPage'));
const InstructionsPage        = asyncComponent(() => import('../../InstructionsPage'));
const ContactPage             = asyncComponent(() => import('../../ContactPage'));
const PrivacyPolicyPage       = asyncComponent(() => import('../../PrivacyPolicyPage'));
const TermsOfServicePage      = asyncComponent(() => import('../../TermsOfServicePage'));

const SignUpPage              = asyncComponent(() => import('../../SignUpPage'));
const SignInPage              = asyncComponent(() => import('../../SignInPage'));
const SignOutPage             = asyncComponent(() => import('../../SignOutPage'));
const PasswordResetPage       = asyncComponent(() => import('../../PasswordResetPage'));

const UserDashBoardPage       = asyncComponent(() => import('../../UserDashBoardPage'));
const UserAccountDetailsPage  = asyncComponent(() => import('../../UserAccountDetailsPage'));
const OrdersListPage          = asyncComponent(() => import('../../OrdersListPage'));
const OrderPage               = asyncComponent(() => import('../../OrderPage'));
const PluginDownloadPage      = asyncComponent(() => import('../../PluginDownloadPage'));
const UserListPage            = asyncComponent(() => import('../../UserListPage'));
const UserPage                = asyncComponent(() => import('../../UserPage'));

const AdminSection            = asyncComponent(() => import('../../AdminSection'));
const WorkshopPage            = asyncComponent(() => import('../../Workshop'));
const CartPage                = asyncComponent(() => import('../../Cart/Cart'));


const Routes = ({ user_id = 0, user_role = '' }) => (
  <Switch>

    <Route path="/" exact={ true } component={ LandingPage } />
    <Route path="/pricing" component={ PricingPage } />
    <Route path="/faq" component={ FaqPage } />
    <Route path="/instructions" component={ InstructionsPage } />
    <Route path="/contact" component={ ContactPage } />
    <Route path="/privacy_policy" component={ PrivacyPolicyPage } />
    <Route path="/eula" component={ TermsOfServicePage } />
    {/* <Route path="/workshop" component={ WorkshopPage } /> */}

    <Route path="/sign_up" component={ SignUpPage } />
    <Route path="/email_verification" component={ SignUpPage } />

    { user_id ? <Route path="/sign_in"><Redirect to="/appx/add-order" /></Route> : <Route path="/sign_in" component={ SignInPage } /> }
    { user_id ? <Route path="/sign_out" component={ SignOutPage } /> : <Route path="/sign_out"><Redirect to="/sign_in" /></Route> }
    { user_id ? <Route path="/password_reset"><Redirect to="/add-order" /></Route> : <Route path="/password_reset" component={ PasswordResetPage } /> }

    <Route path="/appx/dashboard" component={ user_id ? UserDashBoardPage : SignInPage } />
    <Route path="/appx/orders" component={ user_id ? OrdersListPage : SignInPage } />
    <Route path="/appx/add-order" component={ user_id ? OrderPage : SignInPage } />
    <Route path="/appx/order/:order_id" component={ user_id ? OrderPage : SignInPage } />
    <Route path="/appx/plugin_download" component={ user_id ? PluginDownloadPage : SignInPage } />
    <Route path="/appx/account_details" component={ user_id ? UserAccountDetailsPage : SignInPage } />
    <Route path="/appx/cart" component={ user_id ? CartPage : SignInPage } />

    { user_id && user_role === 'admin' && <Route path="/appx/users" component={ UserListPage } /> }
    { user_id && user_role === 'admin' && <Route path="/appx/user_create" component={ UserPage } /> }
    { user_id && user_role === 'admin' && <Route path="/appx/user/:user_id" component={ UserPage } /> }

    { user_id && user_role === 'admin' && <Route path="/admin" component={ AdminSection } /> }

    <Route path="/appx" component={ user_id ? OrderPage : SignInPage } />
    <Route component={ LandingPage } />

  </Switch>
);


Routes.propTypes = {
  user_id: PropTypes.number,
  user_role: PropTypes.string
};


export default connect(
  state => ({
    user_id: state.appUser.user_id,
    user_role: state.appUser.user_role
  })
)(Routes);
