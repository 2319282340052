/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */


const userState = {
  user_id: 0,
  name: '',
  email: '',
  tos_accepted: false,
  status: '',
  user_role: '',
  created_at: '',
  userIsLoading: false,
  company_name: '', billing_address: '', billing_email: '', registration_number: '', vat_number: ''
};


export default ( state = userState, action = {} ) => {
  const { type } = action;
  switch (type) {
    case 'setUser': {
      const { user_id, name, email, tos_accepted, status, user_role, created_at, company_name, billing_address, billing_email, registration_number, vat_number } = action.user;
      return { ...state, user_id, name, email, tos_accepted, status, user_role, created_at, company_name, billing_address, billing_email, registration_number, vat_number };
    }
    case 'clearUser':
      return userState;
    case 'setUserIsLoading': {
      const { userIsLoading } = action;
      return { ...state, userIsLoading };
    }
    default:
      return state;
  }
};
