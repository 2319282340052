/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import {
  getAppUserStoredToken,
  setAppUserStoredToken,
  removeAppUserStoredToken
} from '../../helpers';
import {
  apiGetUserByAuthToken,
  apiPostUserSignIn,
  apiPostUserSignOut,
  apiPostUserSignUp,
  apiPostUserEmailVerificationToken,
//apiPostUserUpdate,
  apiPostPasswordInitiatePasswordReset,
  apiPostPasswordResetPassword,
  apiPostPasswordChangePassword,
  apiPostSendVerificationEmail
} from '../../api';
import { showSuccessMessage, showErrorMessage } from '../../store/actions';




export const setAppUser = appUser => {
  return { type: 'setAppUser', payload: appUser };
};


export const unsetAppUser = () => {
  return { type: 'unsetAppUser' };
};


export const setAppUserToken = token => {
  return { type: 'setAppUserToken', payload: token };
};






export const getUserByAuthToken = () => {
  return async dispatch => {
    const token = getAppUserStoredToken();
    if (!token) {
      return dispatch(unsetAppUser());
    }
    const appUser = await apiGetUserByAuthToken();
    if (appUser && appUser.user_id) {
      appUser.token = token;
	  return dispatch(setAppUser(appUser));
	}

	return dispatch(unsetAppUser());
  };
};


export const postUserSignUp = ({ name, email, password, password_repeat, tos_accepted }) => {
  return async dispatch => {
    const appUser = await apiPostUserSignUp({ name, email, password, password_repeat, tos_accepted });
    if (appUser.errors) {
      dispatch(showErrorMessage(appUser.errors));
    } else {
      setAppUserStoredToken({ token: appUser.token, remember_me: false });
      dispatch(setAppUser(appUser));
	  if (appUser.status !== 'confirmed') {
		await apiPostSendVerificationEmail();
	  } else {
		dispatch(showSuccessMessage('Successfully registered!'));
	  }
    }
    return appUser;
  };
};


export const postUserEmailVerificationToken = ({ verification_token }) => {
  return async dispatch => {
    const response = await apiPostUserEmailVerificationToken({ verification_token });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    }
    return response;
  };
};


export const postUserSignIn = ({ email, password, remember_me }) => {
  return async dispatch => {
    const appUser = await apiPostUserSignIn({ email, password });
    if (appUser.errors) {
      dispatch(showErrorMessage(appUser.errors));
    } else {
      setAppUserStoredToken({ token: appUser.token, remember_me });
      dispatch(setAppUser(appUser));
    }
    return appUser;
  };
};


export const postUserSignOut = () => {
  return async dispatch => {
    await apiPostUserSignOut();
    removeAppUserStoredToken();
    dispatch(unsetAppUser());
  };
};


export const postPasswordInitiatePasswordReset = ({ email }) => {
  return async dispatch => {
    const response = await apiPostPasswordInitiatePasswordReset({ email });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    }
    return response;
  };
};


export const postPasswordResetPassword = ({ email, password, password_repeat, password_reset_token }) => {
  return async dispatch => {
    const response = await apiPostPasswordResetPassword({ email, password, password_repeat, password_reset_token });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    }
    return response;
  };
};


export const postPasswordChangePassword = ({ current_password, password, password_repeat }) => {
  return async dispatch => {
    const response = await apiPostPasswordChangePassword({ current_password, password, password_repeat });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(showSuccessMessage('Password changed'));
    }
    return response;
  };
};


export const postSendVerificationEmail = () => {
  return async dispatch => {
    const response = await apiPostSendVerificationEmail();
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(showSuccessMessage('Email sent'));
    }
    return response;
  };
};

/**
 * postUserUpdate
 */
/*
export const postUserUpdate = ({ name }) => {
  return async dispatch => {
    const response = await apiPostUserUpdate({ name });
    if (response.errors) {
      dispatch(showErrorMessage(response.errors));
    } else {
      dispatch(setAppUser(response));
      dispatch(showSuccessMessage('Saved'));
    }
    return response;
  };
};
*/
