/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { supportedLanguages } from '../config';

export * from './cookie';
export * from './appUserStoredToken';



const supportedLanguagesObject = {};
supportedLanguages.forEach(language => {
  const { code, name } = language;
  supportedLanguagesObject[code] = name;
});
export const getLanguageName = code => {
  return supportedLanguagesObject[code] || code;
};


export const formatDateTime = (timestamp, format = 'YYYY-m-d H:i') => {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  switch (format) {
    case 'YYYY-m-d H:i':
    default:
      month = month + 1;
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
  }
};
