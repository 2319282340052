import React from 'react';

const Svg = () => (
<svg style={{display:'none', color: 'black'}} xmlns="http://www.w3.org/2000/svg">
    <symbol id="header-logo" viewBox="0 0 643 112.5">
        <polygon fill="#ED1D48" points="149.9,73.1 132.5,73.1 132.5,112.5 120.5,112.5 120.5,73.1 103,73.1 103,64.3 149.9,64.3 "/>
        <polygon fill="#ED1D48" points="192.9,112.5 157.3,112.5 157.3,64.3 192.4,64.3 192.4,73.1 169.3,73.1 169.3,83.2 191.3,83.2
            191.3,92.1 169.3,92.1 169.3,103.6 192.9,103.6 "/>
        <path fill="#ED1D48" d="M223.6,64.3c9.9,0,15.8,6.6,15.8,13.8c0,5.3-2.8,9.3-8.3,11.8c4.9,2.5,6.4,8.4,13.2,22.5h-13.2
            c-5.2-10.3-7.1-19.6-13.6-19.6H214v19.6h-12V64.3H223.6z M214,84.1h6.4c3.3,0,6.6-1.4,6.6-5.5c0-3.6-2.3-5.4-6.9-5.4H214V84.1z"/>
        <path fill="#ED1D48" d="M271.4,64.3c9.9,0,15.7,6.6,15.7,13.8c0,5.3-2.8,9.3-8.3,11.8c4.9,2.5,6.4,8.4,13.2,22.5h-13.2
            c-5.2-10.3-7.1-19.6-13.6-19.6h-3.5v19.6h-12V64.3H271.4z M261.9,84.1h6.4c3.3,0,6.6-1.4,6.6-5.5c0-3.6-2.3-5.4-6.9-5.4h-6.1V84.1z"
            />
        <path fill="#ED1D48" d="M347.2,112.5h-12.9l-4-9.5h-21.5l-3.6,9.5h-12.7l19.4-48.2h13.9L347.2,112.5z M319.1,75.8L312,94.5h14.8
            L319.1,75.8z"/>
        <polygon fill="#3FBEBA" points="359.6,106.3 381.3,106.3 381.3,112.5 352.7,112.5 352.7,64.3 359.6,64.3 "/>
        <polygon fill="#3FBEBA" points="414.7,70.5 394.3,70.5 394.3,85.1 414,85.1 414,91.3 394.3,91.3 394.3,106.3 415.3,106.3 415.3,112.5
            387.4,112.5 387.4,64.3 414.7,64.3 "/>
        <polygon fill="#3FBEBA" points="446.7,87.5 467.2,112.5 458.9,112.5 442.5,92.6 425.9,112.5 417.5,112.5 438.3,87.5 419,64.3
            427.4,64.3 442.5,82.7 457.6,64.3 465.9,64.3 "/>
        <rect x="472.7" y="64.3"  fill="#3FBEBA" width="6.9" height="48.2"/>
        <path fill="#3FBEBA" d="M531.4,75c-5.9-3.5-11-5-16.6-5c-11,0-19.2,7.8-19.2,18.4c0,10.8,7.9,18.3,19.5,18.3c5.4,0,11.4-1.9,16.8-5.4
            v7.6c-4.7,2.5-9.9,4.2-17.4,4.2c-18.1,0-26-13.9-26-24c0-14.7,11.1-25.4,26.4-25.4c4.9,0,10,1.2,16.5,4V75z"/>
        <path fill="#3FBEBA" d="M564.1,63.7C580,63.7,590,75,590,88.4c0,13-9.7,24.7-26.1,24.7c-15.7,0-25.7-11.7-25.7-24.7
            C538.2,75,548.5,63.7,564.1,63.7 M564.1,106.8c10.8,0,18.8-7.8,18.8-18.4c0-10.6-8-18.4-18.8-18.4c-10.7,0-18.7,7.8-18.7,18.4
            C545.3,98.9,553.3,106.8,564.1,106.8"/>
        <polygon fill="#3FBEBA" points="643,112.5 637.1,112.5 604.9,75.4 604.9,112.5 598.4,112.5 598.4,64.3 603.9,64.3 636.5,101.6
            636.5,64.3 643,64.3 "/>
        <rect  fill="#ED1D48" x="88.4" y="0" width="20.1" height="20.1"/>
        <polygon  fill="#3FBEBA" points="80.4,28.1 80.4,8 60.3,8 60.3,48.2 96.4,48.2 96.4,28.1 "/>
        <polygon fill="#ED1D48" points="52.2,56.3 52.2,16.1 0,16.1 0,56.3 32.2,56.3 32.2,112.5 72.3,112.5 72.3,56.3 "/>
    </symbol>

    <symbol id="footer-logo" viewBox="0 0 108.5 112.6">
        <rect fill="#FF0043"  x="88.4" width="20.1" height="20.1"/>
        <polygon fill="#00CFC5"  points="80.4,28.1 80.4,8 60.3,8 60.3,48.2 96.5,48.2 96.5,28.1 "/>
        <polygon fill="#FF0043"  points="52.3,56.3 52.3,16.1 0,16.1 0,56.3 32.2,56.3 32.2,112.6 72.3,112.6 72.3,56.3 "/>
    </symbol>

    <symbol id="icon-down" viewBox="0 0 38.9 40.2">
        <g>
            <path d="M31.6,13.5l-7.3,7.3V0h-9.8v20.8l-7.3-7.3L0,20.7l12.2,12.2c2.4,2.4,4.8,4.8,7.2,7.2c2.4-2.4,4.8-4.8,7.2-7.2l12.2-12.2
                L31.6,13.5z"/>
        </g>
    </symbol>

    <symbol id="icon-fb" viewBox="0 0 24 24">
        <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
    </symbol>

    <symbol id="icon-tw" viewBox="0 0 24 24">
        <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
    </symbol>

    <symbol id="icon-ld" viewBox="0 0 24 24">
        <path d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
    </symbol>
</svg>);

export default Svg;
