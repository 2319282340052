/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */


export const addMessage = message => {
  return { type: 'addMessage', message };
};


export const dismissMessage = message => {
  return { type: 'dismissMessage', message };
};


export const removeMessage = message => {
  return { type: 'removeMessage', message };
};




export const showMessage = message => {
  return async dispatch => {
    if (message) {
      if (typeof message === 'string') {
        message = { text: message };
      }
      message.id = new Date().getTime().toString() + Math.random().toString();
      message.type = message.type || 'info';
      message.text = message.text || 'What´s the message...?';
      message.duration = message.duration || 3000;
      message.removeTimeoutFunction = setTimeout(() => { dispatch(removeMessage(message)); }, message.duration + 2000);
      dispatch(addMessage(message));
    }
  }
};


export const showInfoMessage = message => {
  return async dispatch => {
    message = message || '';
    if (typeof message === 'string') {
      message = { text: message };
    }
    message.type = 'info';
    dispatch(showMessage(message));
  }
};


export const showSuccessMessage = message => {
  return async dispatch => {
    message = message || 'Success';
    if (typeof message === 'string') {
      message = { text: message };
    }
    message.type = 'success';
    message.duration = 3000;
    dispatch(showMessage(message));
  }
};


export const showWarningMessage = message => {
  return async dispatch => {
    message = message || 'Warning';
    if (typeof message === 'string') {
      message = { text: message };
    }
    message.type = 'warning';
    dispatch(showMessage(message));
  }
};


export const showErrorMessage = message => {
  return async dispatch => {
    message = message || 'Unknown error';
    if (typeof message === 'string') {
      // 'this is an error'
      message = { text: message };
    } else {
      if (typeof message.text === 'string') {
        // { text: 'this is an error' }
      } else if (message.status && message.statusText && message.url) {
        // { status: 404, statusText: 'Not found', url: 'http://' }
        message = { text: message.status + ' ' + message.statusText  + ' /' + message.url.split('/').splice(3).join('/') };
      } else {
        // { order_name: 'this is an error', object_type: 'this is an error' }
        Object.keys(message).forEach(field => {
          const text = typeof message[field] === 'object' && message[field][0] ? message[field][0] : message[field];
          const msg = { field, text, type: 'error', duration: 5000 };
          dispatch(showMessage(msg));
        });
        return;
      }
    }
    message.type = 'error';
    message.duration = 5000;
    dispatch(showMessage(message));
  }
};
