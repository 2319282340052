/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { apiGet, apiPost, apiDelete } from './api';
import { apiGetOrders } from './orders';


/**
 * apiGetOrder
 * @returns onSuccess {
 *    order_id: 6,
 *    order_token: "811825cc-349e-427f-84ec-3299e017a0aa",
 *    order_name: "server test123",
 *    order_status: "payment_done",
 *    order_added: "2020-05-21T10:59:51Z",
 *    object_type: "geonames",
 *    from_lang: "lv",
 *    to_lang: "sv",
 *    files: [],
 *    extractedData: [],
 *    showTranslations: false,
 *    canBeTranslated:false
 * }
 */
export const apiGetOrder = async ({ order_id }) => {
  const response = await apiGet(`/order/${order_id}`);
  {
    const { id: order_id, object_type, order_token, order_name, from_lang, to_lang, order_added, order_status } = response;
    const order = {
      order_id,
      order_token,
      order_name,
      order_status,
      order_added,
      object_type,
      from_lang,
      to_lang,
      files: [],
      extractedData: [],
      showTranslations: false,
      canBeTranslated: false
    };
    return order;
  }
};


/**
 * apiGetOrderDetailed
 * @returns onSuccess {
 *    order_id: 6,
 *    order_token: "811825cc-349e-427f-84ec-3299e017a0aa",
 *    order_name: "server test123",
 *    order_status: "payment_done",
 *    order_added: "2020-05-21T10:59:51Z",
 *    object_type: "geonames",
 *    from_lang: "lv",
 *    to_lang: "sv",
 *    files: [ "KK900_Latv_tituls_27km2", "KK911_Latv_tituls_27km2" ],
 *    extractedData: [{ label:"Ķekava", translation: "Ķekava", uuid: "cee153aa-9103-4b8f-9111-b8613aa60d7e" }, { label: "Krāslava", translation: "Krāslava", uuid: "1bde8173-394f-4fa6-b6a0-00ee6350fe99" }],
 *    showTranslations: true,
 *    canBeTranslated:true
 * }
 */
export const apiGetOrderDetailed = async ({ order_id }) => {
  const response = await apiGet(`/order/${order_id}/detailed`);
  {
    const { meta, extractedData, files, canBeTranslated, showTranslations, can_user_approve_translations, translation_preview } = response;
    const { id: order_id, object_type, order_token, order_name, from_lang, to_lang, order_added, order_status, has_error, too_old_tool } = meta;
    extractedData.forEach(item => item.translation = item.translation || '');
    const order = {
      order_id,
      order_token,
      order_name,
      order_status,
      order_added,
      object_type,
      from_lang,
      to_lang,
      files,
      extractedData,
      showTranslations,
	  translationPreview: translation_preview,
	  canUserApproveTranslations: can_user_approve_translations,
	  canBeTranslated,
	  has_error: has_error && has_error === 1 ? true : false,
	  too_old_tool: too_old_tool && too_old_tool === 1 ? true : false
    };
    return order;
  }
};


/**
 * apiGetOrderStatus
 */
export const apiGetOrderStatus = async ({ order_id }) => {
  const response = await apiGet(`/order/${order_id}`);
  const { order_status, has_error, too_old_tool } = response;
  return { order_status, has_error: has_error && has_error === 1 ? true : false,
	too_old_tool: too_old_tool && too_old_tool === 1 ? true : false };
};

/**
 * apiDeleteOrder
 */
export const apiDeleteOrder = async ({ order_id }) => {
	const response = await apiDelete(`/order/${order_id}/delete`);
	return response;
  };

/**
 * apiGetOrderStatistics
 */
export const apiGetOrderStatistics = async ({ order_id }) => {
	const response = await apiGet(`/order/${order_id}/statistics`);
	const statistics = response;
	if ('status' in statistics) {
		return [];
	}

	return statistics;
  };

/**
 * apiPostOrderRegion
 */
export const apiPostOrderRegion = async ({ order_id, region, file }) => {
	const body = {
		region,
		file
	};
	let response = await apiPost(`/order/${order_id}/set_file_region`, body);
	return response || {};
};

/**
 * apiGetOrderRegion
 */
export const apiGetOrderRegion = async ({ order_id }) => {
	return await apiGet(`/order/${order_id}/regions`);
};


/**
 * apiPostOrderUpdate
 * @returns onSuccess {
 *    order_id: 6,
 *    order_token: "811825cc-349e-427f-84ec-3299e017a0aa",
 *    order_name: "server test123",
 *    order_status: "payment_done",
 *    order_added: "2020-05-21T10:59:51Z",
 *    object_type: "geonames",
 *    from_lang: "lv",
 *    to_lang: "sv",
 *    files: [ "KK900_Latv_tituls_27km2", "KK911_Latv_tituls_27km2" ],
 *    extractedData: [{ label:"Ķekava", translation: "Ķekava", uuid: "cee153aa-9103-4b8f-9111-b8613aa60d7e" }, { label: "Krāslava", translation: "Krāslava", uuid: "1bde8173-394f-4fa6-b6a0-00ee6350fe99" }],
 *    showTranslations: true,
 *    canBeTranslated:true
 * }
 * @returns onError {
 *    errors: {
 *      order_name: [ "This field is required." ],
 *      from_lang: [ "This field is required." ]
 *    }
 * }
 */
export const apiPostOrderUpdate = async ({ order_id, order_name, object_type, from_lang, to_lang }) => {
  const body = {
    name: order_name,
    dataType: object_type,
    langFrom: from_lang,
    langTo: to_lang
  };
  let response = await apiPost(`/order/${order_id}/update`, body);
  response = response || {};
  if (response.status === 'ok') {
    const order = await apiGetOrder({ order_id });
    return order;
  }
  if (response.errors) {
    const errors = {};
    Object.keys(response.errors).forEach(field => {
      const text = response.errors[field][0];
      switch (field) {
        case 'name':
          field = 'order_name';
        break;
        case 'dataType':
          field = 'object_type';
        break;
        case 'langFrom':
          field = 'from_lang';
        break;
        case 'langTo':
          field = 'to_lang';
        break;
        default:
        break;
      }
      errors[field] = text;
    });
    return { errors };
  }
  return { errors: { unspecified: 'Unspecified error' } };
};



/**
 * apiPostOrderNew
 * @returns onSuccess {
 *    order_id: 6,
 *    order_token: "811825cc-349e-427f-84ec-3299e017a0aa",
 *    order_name: "server test123",
 *    order_status: "payment_done",
 *    order_added: "2020-05-21T10:59:51Z",
 *    object_type: "geonames",
 *    from_lang: "lv",
 *    to_lang: "sv",
 *    files: [],
 *    extractedData: [],
 *    showTranslations: false,
 *    canBeTranslated: false
 * }
 * @returns onError {
 *    errors: {
 *      order_name: [ "This field is required." ],
 *      from_lang: [ "This field is required." ]
 *    }
 * }
 */
export const apiPostOrderNew = async ({ order_name, object_type, from_lang, to_lang }) => {
  const body = {
    name: order_name,
    dataType: object_type,
    langFrom: from_lang,
    langTo: to_lang
  };
  let response = await apiPost(`/order/new`, body);
  response = response || {};
  if (response.data && response.data.token) {
    const order_token = response.data.token;
    const ordersList = await apiGetOrders();
    const order_id = (ordersList.find(item => order_token === item.order_token) || {}).order_id;
    if (order_id) {
      const order = await apiGetOrder({ order_id });
      return order;
    }
  }
  if (response.errors) {
    const errors = {};
    Object.keys(response.errors).forEach(field => {
      const text = response.errors[field][0];
      switch (field) {
        case 'name':
          field = 'order_name';
        break;
        case 'dataType':
          field = 'object_type';
        break;
        case 'langFrom':
          field = 'from_lang';
        break;
        case 'langTo':
          field = 'to_lang';
        break;
        default:
        break;
      }
      errors[field] = text;
    });
    return { errors };
  }
  return { errors: { unspecified: 'Unspecified error' } };
};




export const apiPostOrderUpdateStatus = async ({ order_id, order_status }) => {
  return await apiPost(`/order/${order_id}/update_status`, { status: order_status });
};


export const apiPostOrderInitializeTranslation = async ({ order_id }) => {
  return await apiPost(`/order/${order_id}/initialize_translation`);
};


export const apiPostOrderApproveTranslations = async ({ order_id }) => {
  return await apiPostOrderUpdateStatus({ order_id, order_status: 'user_approved_translations' });
};


export const apiPostOrderPaymentWaiting = async ({ order_id }) => {
  return await apiPostOrderUpdateStatus({ order_id, order_status: 'payment_waiting' });
};


export const apiPostOrderPaymentDone = async ({ order_id }) => {
  return await apiPostOrderUpdateStatus({ order_id, order_status: 'payment_done' });
};


export const apiPostOrderFinished = async ({ order_id }) => {
  return await apiPostOrderUpdateStatus({ order_id, order_status: 'finished' });
};


export const apiPostOrderUpdateLabel = async ({ uuid, label, translation }) => {
  return await apiPost(`/label/${uuid}/update`, { label, translation });
};


export const apiDeleteLabel = async ({ order_id, uuid }) => {
	const response = await apiPost(`/label/${uuid}/update`, {deleted: true});
	return response;
};

export const apiSetAsReadOldPluginNotification = async ({ order_id }) => {
	const response = await apiPost(`/order/${order_id}/set_notification_as_read`);
	return response;
};

export const apiSetDiscount = async (code) => {
	const response = await apiPost(`/user/set_discount`, { code });
	return response;
};
