/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */


const messagesState = [
  /*
  {
    id: new Date().getTime().toString() + Math.random().toString(),
    type: 'error',          // info | success | warning | error
    text: 'this is error',
    duration: 3000,         // ms
    dismissed: false
  }
  */
];


export default (state = messagesState, { type, message }) => {
  switch (type) {
    case 'addMessage':
      {
        message.id = message.id || new Date().getTime().toString() + Math.random().toString();
        message.type = message.type || 'info';
        message.duration = message.duration || 3000;
        message.dismissed = false;
        state.push(message)
        return [ ...state ];
      }
    case 'removeMessage':
      {
        message = state.find(msg => message === msg);
        if (message) {
          clearTimeout(message.removeTimeoutFunction);
          state = state.filter(msg => message !== msg);
        }
        return state;
      }
    case 'dismissMessage':
      {
        message = state.find(msg => message === msg);
        if (message) {
          message.dismissed = true;
          state = [ ...state ];
        }
        return state;
      }
    default:
      return state;
  }
};
