/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, NavLink } from 'react-router-dom';

const HeaderNavi = ({ user_id = 0, user_name = '', user_role = '' }) => {
  return (
    <div className="header__nav">
      <ul className="header__ul">
        <li style={{ display: 'none' }}><NavLink className="header__link" to="/">TL</NavLink></li>
         <Switch>
         {
          user_id &&
            <Route path="/appx">
              {/* <li className="header__item"><NavLink className="header__link" to="/appx/dashboard">Dashboard</NavLink></li> */}
              <li className="header__item"><NavLink className="header__link" to="/appx/add-order">Create order</NavLink></li>
              <li className="header__item"><NavLink className="header__link" to="/appx/orders">Orders list</NavLink></li>
              <li className="header__item"><NavLink className="header__link" to="/appx/plugin_download">Plugin download</NavLink></li>
              { user_role === 'admin' ? <li className="header__item"><NavLink className="header__link" to="/appx/users">Users list</NavLink></li> : null }
              <li className="header__item"><NavLink className="header__link" to="/appx/account_details" title={ user_name }>My account</NavLink></li>
              { user_role === 'admin' ? <li className="header__item"><NavLink className="header__link" to="/admin">Administration</NavLink></li> : null }
              <li className="header__item"><NavLink className="header__link" to="/appx/cart">Cart</NavLink></li>
			<li className="header__item">
			  <a
			  className="header__link" href="mailto:info@terralexicon.eu">Contact us</a>
			</li>
              <li className="header__item"><NavLink className="header__link" to="/sign_out">Sign out</NavLink></li>
            </Route>
        }
        <Route path="/">
          <li className="header__item">
            <NavLink
            className="header__link" to="/#features" exact={ true }>Features</NavLink>
          </li>

          <li className="header__item">
            <NavLink
            className="header__link" to="/#pricing" exact={ true }>Pricing</NavLink>
          </li>

          <li className="header__item">
            <NavLink
            className="header__link" to="/#about-us" exact={ true }>About us</NavLink>
          </li>

          <li className="header__item">
            <NavLink
            className="header__link" to="/#contact" exact={ true }>Contact</NavLink>
          </li>
          { user_id ? <li className="header__item"><NavLink className="header__link" to="/appx/add-order" title={ user_name }>My account</NavLink></li> : null }
          { user_id ? <li className="header__item"><NavLink className="header__link" to="/sign_out">Sign out</NavLink></li> : null }
          { !user_id ? <li className="header__item"><NavLink className="header__link" to="/sign_in">Sign in</NavLink></li> : null }
          { !user_id ? <li className="header__item"><NavLink className="header__link" to="/sign_up">Sign up</NavLink></li> : null }
          <li className="header__item header__langcontainer"><NavLink className="header__langitem" to="/en">EN</NavLink>|<NavLink className="header__langitem" to="/lv">LV</NavLink>|<NavLink className="header__langitem" to="/et">ET</NavLink></li>
        </Route>
      </Switch>
      </ul>

    </div>
  );
};


HeaderNavi.propTypes = {
  user_id: PropTypes.number.isRequired,
  user_name: PropTypes.string.isRequired,
  user_role: PropTypes.string.isRequired
};


export default connect(
  state => ({
    user_id: state.appUser.user_id,
    user_name: state.appUser.name,
    user_role: state.appUser.user_role
  })
)(HeaderNavi);
