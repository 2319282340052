/**
 * @author jevgeni dot virves at regio dot ee
 * @since 2020-07-13
 */

import { API_URL, appUserAuthTokenName } from '../config';
import {getAppUserStoredToken} from '../helpers/appUserStoredToken';



/**
 * apiRequest
 */
export const apiRequest = async (url, method, body) => {
	return promise(url, method, body)
	  .then(async res => {
		if (res.status === 200) {
		  return res.json();
		}
		throw res;
	  })
	  .catch(error => {
		return { status: 'error', errors: error };
	  });
  };


export const promise = async (url, method, body) => {
  const token = getAppUserStoredToken();

  url = API_URL + url;
  url = url.replace(/(\w+)\/\//, '$1/');  // remove double slash within url //tl-portal-api.kartes.lv/api//orders
  method = (method || 'GET').toString().toUpperCase();
  method = ['GET', 'POST', 'PUT', 'DELETE'].indexOf(method) !== -1 ? method : 'GET';
  switch (method) {
    case 'GET':
      const params = http_build_query(body);
      if (params) {
        url = url + (url.indexOf('?') === -1 ? '?' : '&') + params;
      }
    break;
    default:
    break;
  }
  const options = {
    method,
    mode: 'cors', // cors, no-cors, *cors, same-origin
    cache: 'no-cache',
    credentials: 'include', // same-origin, include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (token && (!options.credentials || options.credentials !== 'omit')) {
    options.headers[appUserAuthTokenName] = 'Bearer ' + btoa(token);
  }
  if (body && method !== 'GET') {
    options.body = JSON.stringify(body);
  }
  return fetch(url, options);
};




export const apiGet = async (url, body) => {
  return await apiRequest(url, 'GET', body);
};


export const apiPost = async (url, body) => {
  return await apiRequest(url, 'POST', body);
};


export const apiPut = async (url, body) => {
	return await apiRequest(url, 'PUT', body);
  };

  export const apiDelete = async (url, body) => {
	return await apiRequest(url, 'DELETE', body);
  };


const http_build_query = body => {
  if (!body || typeof body !== 'object') {
    return '';
  }
  const params = [];
  Object.keys(body).forEach(name => {
    const value = body[name];
    params.push(name + '=' + encodeURIComponent(value));
  });
  return params.join('&');
};
